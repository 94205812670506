import { Block } from "@/blocks/base";
import { Typography } from "@/uikit/Typography";
import { RemoteImage } from "@/uikit/remote-image";
import Head from "next/head";
import React from "react";
import Section from "@/uikit/Section";

import { containerStyle, wrapperStyle, imageContainerStyle } from "./notFound.css";

const NotFound = Block("notFound", ({ className, image, text, title }) => {
  return (
    <div className={`${wrapperStyle} ${className || ""}`}>
      <Head>
        <title>Not Found</title>
        <meta name="description" content="This page is not found" />
      </Head>
      <Section>
        <div className={containerStyle}>
          <Typography.title>{title}</Typography.title>
          <div className={imageContainerStyle}>
            <RemoteImage image={image} alt="alt" />
          </div>
          <Typography>{text}</Typography>
        </div>
      </Section>
    </div>
  );
});

export default NotFound;
